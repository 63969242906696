.Repository {
  text-align: center;
}

* {margin: 0; padding: 0;}

.repository-list {
  margin: 20px;
}

.Repository .modules-list ul {
  list-style-type: none;
  width: 100%;
}

.Repository .modules-list li {
  padding: 10px;
  overflow: auto;
  text-align: left;
}

.Repository .modules-list li:hover {
  background: rgb(227, 241, 245);
  cursor: pointer;
}

.Repository .modules-list li img {
  float: left;
  width: 100px;
  height: 150px;
  margin: 0 15px 0 0;
}

.landing-page .section .repository-list p {
  margin-top: 0;
  line-height: 16px;
  font-size: 16px;
  font-weight: 200;
}

.Repository p {
  font-size: 15px;
  line-height: 1;
}

.module-search {
  width: 500px;
  margin-top: 10px;
  font: 200 18px/1.5 Georgia, Times New Roman, serif;
}

.module-search-number {
  padding: 5px;
  background: rgb(142, 150, 170);
}

/* Parallax overrides */
.landing-page .parallax-small {
  height: 88px;
}

.logo-container .brand {
  margin-top: 15px;
}

.logo-container .logo{
  border: 0;
  border-radius: 0;
}

.modules-search-input {
  margin: 20px auto;
}

.modules-search-input input {
  height: 40px;
  font-size: 15px;
}
