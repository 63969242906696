body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", "Open Sans", Arial, sans-serif;
}

@media screen and (max-width: 768px) {
  .youtube-video-tutorial {
    width: 100%;
  }
}
